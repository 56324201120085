import { Suspense, lazy } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------
// AUTH
export const LoginPage = Loadable(
  lazy(() => import("../pages/login/LoginPage"))
);

export const OverviewPage = Loadable(
  lazy(() => import("../pages/dashboard/overview/OverviewPage"))
);

export const EngineersPage = Loadable(
  lazy(() => import("../pages/dashboard/employees/dar/engineers/EngineersPage"))
);

export const ContractsEmployeesPage = Loadable(
  lazy(() => import("../pages/dashboard/employees/contracts/EmployeesPage"))
);

export const ProjectsPage = Loadable(
  lazy(() => import("../pages/dashboard/projects/ProjectPage"))
);

export const CalendarPage = Loadable(
  lazy(() => import("../pages/dashboard/calendar/CalendarPage"))
);

export const FinancePage = Loadable(
  lazy(() => import("../pages/dashboard/finance/FinancePage"))
);

export const SettingsPage = Loadable(
  lazy(() => import("../pages/settings/SettingsPage"))
);

export const AddDarEmployeePage = Loadable(
  lazy(() =>
    import("../pages/dashboard/employees/dar/projects_managers/AddNewEmployee")
  )
);

export const EntrepreneurClientsPage = Loadable(
  lazy(() => import("../pages/dashboard/clients/entrepreneurs/ClientsPage"))
);

export const PotentialClientsPage = Loadable(
  lazy(() => import("../pages/dashboard/clients/potential/ClientsPage"))
);

export const UsersPage = Loadable(
  lazy(() => import("../pages/dashboard/users/UsersPage"))
);

export const AddNewUserPage = Loadable(
  lazy(() => import("../pages/dashboard/users/CreateUser"))
);

export const EditUserPage = Loadable(
  lazy(() => import("../pages/dashboard/users/EditUser"))
);

export const CreateProject = Loadable(
  lazy(() => import("../pages/dashboard/projects/CreateProject"))
);

export const AppointmentsPage = Loadable(
  lazy(() => import("../pages/dashboard/appointments/AppointmentsPage"))
);

export const EditProject = Loadable(
  lazy(() => import("../pages/dashboard/projects/EditProject"))
);

export const ProjectManagerPage = Loadable(
  lazy(() => import("../pages/dashboard/users/project-managers/ProjectManager"))
);

// project managers
export const ProjectManagersPage = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/employees/dar/projects_managers/ProjectManagersPage"
    )
  )
);

export const CreateProjectManagerPage = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/employees/dar/projects_managers/CreateProjectManager"
    )
  )
);
export const ContractorPage = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/contractors/ContractorPage"
    )
  )
);
export const CreateContractorPage = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/contractors/CreateContractor"
    )
  )
);

export const EditProjectManagerPage = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/employees/dar/projects_managers/EditProjectManager"
    )
  )
);
export const CreateEngineerPage = Loadable(
  lazy(() =>
    import("../pages/dashboard/employees/dar/engineers/CreateEngineer")
  )
);

export const EditContractorPage = Loadable(
  lazy(() =>
    import("../pages/dashboard/contractors/EditContractor")
  )
);
