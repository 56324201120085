import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";

//---------------------------------------------------------------

// GET =>  GET ALL APPOINTMENTS
export const getAllAppointmentsService = createAsyncThunk(
  "appointments/getAllAppointments",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.appointmentsURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

