import { createSlice } from "@reduxjs/toolkit";
import {
  getAllProjects,
  createProjectsService,
  postponeProjectService,
  archiveProjectService,
  resumeProjectService,
  assignProjectManagerService,
  getTypeOfProject,
  setTypeOfProject,
  getProjectById,
  updateProject,
  startProjectService,
  endProjectService,
  assignableUsersService,
  createAssignProjectService,
  getAllAccessibilityService,
  getProjectToPm,
  getProjectToEng,
} from "../services/project_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  projectsList: [],
  phases: [],
  projectListPagination: 0,
  projectId: null,
  projectInfo: null,
  isLoadingTypeOfProject: false,
  types: [],
  isLoadingSetTypeOfProject: false,
  isLoadingProjectById: false,
  projectById: {},
  isLoadingUpdate: false,
  isLoadingState: false,
  assignableUsers: [],
  isLoadingAssignableUsers: false,
  usersListPagination: {},
  isLoadingAssign: false,
  accessibility: [],
  checkedPhases: [],
  projectPm: [],
  projectEng: [],
  isLoadingProjectPm: false,
  isLoadingProjectEn: false,
  projectIds: [],
};

const slice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjectID: (state, action) => {
      state.projectId = action.payload;
    },

    setProjectIDs: (state, action) => {
      //  state.projectId = action.payload;

      const { projectIds } = state;
      const newId = action.payload;

      if (projectIds.includes(newId)) {
        state.projectIds = projectIds.filter((id) => id !== newId);
      } else {
        state.projectIds = [...projectIds, newId];
      }
    },

    setCheckedPhases: (state, action) => {
      state.checkedPhases = action.payload;
    },

    setProjectInfo: (state, action) => {
      state.projectInfo = action.payload;
    },

    setProjectPhase: (state, action) => {
      state.phases = action.payload;
    },
    setProjectInfoNull: (state, action) => {
      state.projectId = null;
      state.projectById = {};
    },
  },
  extraReducers: {
    // ==> GET PROJECTS
    [getAllProjects.fulfilled]: (state, action) => {
      state.isLoading = false;
      console.log(action.payload.data);
      state.projectsList = action.payload.data.data;
      state.projectListPagination = action.payload?.data?.meta;
    },
    [getAllProjects.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAllProjects.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> Create PROJECTS
    [createProjectsService.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createProjectsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [createProjectsService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> START PROJECTS
    [startProjectService.fulfilled]: (state, action) => {
      state.isLoadingState = false;
    },
    [startProjectService.rejected]: (state, action) => {
      state.isLoadingState = false;
    },
    [startProjectService.pending]: (state) => {
      state.isLoadingState = true;
    },

    // ==> End PROJECTS
    [endProjectService.fulfilled]: (state, action) => {
      state.isLoadingState = false;
    },
    [endProjectService.rejected]: (state, action) => {
      state.isLoadingState = false;
    },
    [endProjectService.pending]: (state) => {
      state.isLoadingState = true;
    },

    // ==> POSTPONE PROJECTS
    [postponeProjectService.fulfilled]: (state, action) => {
      state.isLoadingState = false;
    },
    [postponeProjectService.rejected]: (state, action) => {
      state.isLoadingState = false;
    },
    [postponeProjectService.pending]: (state) => {
      state.isLoadingState = true;
    },

    // ==> ARCHIVE PROJECTS
    [archiveProjectService.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [archiveProjectService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [archiveProjectService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> RESUME PROJECTS
    [resumeProjectService.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [resumeProjectService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [resumeProjectService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> ASSIN PROJECTS
    [assignProjectManagerService.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [assignProjectManagerService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [assignProjectManagerService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> GET TYPE OF PROJECT
    [getTypeOfProject.fulfilled]: (state, action) => {
      state.isLoadingTypeOfProject = false;
      state.types = action.payload.data;
    },
    [getTypeOfProject.rejected]: (state, action) => {
      state.isLoadingTypeOfProject = false;
    },
    [getTypeOfProject.pending]: (state) => {
      state.isLoadingTypeOfProject = true;
    },

    // ==> Create TYPE OF PROJECT
    [setTypeOfProject.fulfilled]: (state, action) => {
      state.isLoadingSetTypeOfProject = false;
    },
    [setTypeOfProject.rejected]: (state, action) => {
      state.isLoadingSetTypeOfProject = false;
    },
    [setTypeOfProject.pending]: (state) => {
      state.isLoadingSetTypeOfProject = true;
    },
    // ==> GET PROJECT BY ID
    [getProjectById.fulfilled]: (state, action) => {
      state.isLoadingProjectById = false;
      state.projectById = action.payload.data;
    },
    [getProjectById.rejected]: (state, action) => {
      state.isLoadingProjectById = false;
    },
    [getProjectById.pending]: (state) => {
      state.isLoadingProjectById = true;
    },

    // ==> UPDATE PROJECTS
    [updateProject.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateProject.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateProject.pending]: (state) => {
      state.isLoadingUpdate = true;
    },

    // ==> assignable Users
    [assignableUsersService.fulfilled]: (state, action) => {
      state.isLoadingAssignableUsers = false;
      state.assignableUsers = action.payload.data;
      state.usersListPagination = action.payload.meta;
    },
    [assignableUsersService.rejected]: (state, action) => {
      state.isLoadingAssignableUsers = false;
    },
    [assignableUsersService.pending]: (state) => {
      state.isLoadingAssignableUsers = true;
    },

    // POST =>  CREATE ASSIGN PROJECT
    [createAssignProjectService.fulfilled]: (state, action) => {
      state.isLoadingAssign = false;
    },
    [createAssignProjectService.rejected]: (state, action) => {
      state.isLoadingAssign = false;
    },
    [createAssignProjectService.pending]: (state) => {
      state.isLoadingAssign = true;
    },

    // POST =>  CREATE ASSIGN PROJECT
    [getAllAccessibilityService.fulfilled]: (state, action) => {
      console.log("from slice");
      console.log(action.payload.data);
      state.accessibility = action.payload.data;
    },
    [getAllAccessibilityService.rejected]: (state, action) => {
      //   state.isLoading = false;
    },
    [getAllAccessibilityService.pending]: (state) => {
      //  state.isLoading = true;
    },

    // ==> GET PROJECTS Pm
    [getProjectToPm.fulfilled]: (state, action) => {
      state.isLoadingProjectPm = false;
      state.projectsList = action.payload.data;
      state.projectListPagination = action.payload?.meta;
    },
    [getProjectToPm.rejected]: (state, action) => {
      state.isLoadingProjectPm = false;
    },
    [getProjectToPm.pending]: (state) => {
      state.isLoadingProjectPm = true;
    },

    // ==> GET PROJECTS ENGINEER
    [getProjectToEng.fulfilled]: (state, action) => {
      state.isLoadingProjectEn = false;
      state.projectsList = action.payload.data;
      state.projectListPagination = action.payload?.meta;
    },
    [getProjectToEng.rejected]: (state, action) => {
      state.isLoadingProjectEn = false;
    },
    [getProjectToEng.pending]: (state) => {
      state.isLoadingProjectEn = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setProjectInfo,
  setProjectID,
  setProjectIDs,
  setProjectPhase,
  setProjectInfoNull,
  setCheckedPhases,
} = slice.actions;
