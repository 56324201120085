// @mui
import { alpha, styled } from "@mui/material/styles";
import {
  ListItem,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
} from "@mui/material";
// config
import { ICON, NAV } from "../../../config";

// ----------------------------------------------------------------------

export const StyledItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "caption",
})(({ theme }) => {
  const isLight = theme.palette.mode === "light";
  return {
    position: "relative",
    textTransform: "capitalize",
    color: "#535353",
    height: NAV.H_DASHBOARD_ITEM,
    paddingLeft: "33px",
    fontSize: "9px",
    "&:hover": {
      "& .MuiTypography-root": {
        color: "#227284",
      },
      "& .MuiListItemIcon-root": {
        color: "#227284",
      },
      backgroundColor: theme.palette.primary.main,
      color: "#227284",
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: theme.palette.primary.main,
      color: "#227284",
    },
  };
});

// ----------------------------------------------------------------------

export const StyledIcon = styled(ListItemIcon)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: ICON.NAV_ITEM,
  height: ICON.NAV_ITEM,
  marginRight: "16px",
});

// ----------------------------------------------------------------------

export const StyledDotIcon = styled("span", {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active, theme }) => ({
  display: "none",
  width: 4,
  height: 4,
  borderRadius: "50%",
  backgroundColor: theme.palette.text.disabled,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shorter,
  }),
  ...(active && {
    transform: "scale(2)",
    backgroundColor: theme.palette.primary.main,
  }),
}));

// ----------------------------------------------------------------------

export const StyledSubheader = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.overline,
  fontSize: 11,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
