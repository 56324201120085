import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";

//---------------------------------------------------------------

// GET =>  GET ALL Cities
export const getAllCitiesService = createAsyncThunk(
  "cities/getAllCities",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.citiesURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  CREATE CITY
export const createCityService = createAsyncThunk(
  "cities/createCity",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.citiesURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// DELTE =>  DELETE CITY
export const deleteCityService = createAsyncThunk(
  "cities/deleteCity",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.citiesURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// PUT =>  UPDATE City
export const updateCityService = createAsyncThunk(
  "cities/updateCity",
  async (payload, thunkAPI) => {
    console.log(payload);
    try {
      const response = await axios.put(
        `${URLS.citiesURL}/${payload.id}`,
        payload.data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET ALL PROVINCES
export const getAllProvincesService = createAsyncThunk(
  "provinces/getAllProvinces",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.provincesURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
