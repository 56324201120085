// routes
import { PATH_DASHBOARD } from "../../../routes/paths";

import SvgColor from "../../../components/svg-color";
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  dashboard: icon("dashboard"),
  calendar: icon("calendar"),
  employees: icon("employees"),
  clients: icon("clients"),
  projects: icon("home"),
  wallet: icon("wallet"),
  settings: icon("settings"),

};

const navConfig = [
  // Admin
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      {
        title: "dashboard",
        icon: ICONS.dashboard,
        path: "/dashboard/overview",
        index: "1",
      },
      {
        title: "calendar",
        icon: ICONS.calendar,
        path: "/dashboard/calendar",
        index: "2",
      },
      {
        title: "contractors",
        icon: ICONS.employees,
        path: "/dashboard/contractor",
        index: "2",
      },
     /* {
        title: "users",
        icon: ICONS.calendar,
        path: "/dashboard/users",
        index: "2",
      },*/
      {

        title: "employees",
        icon: ICONS.employees,
        path: "/employees",
        index: "3",
        children: [
          { title: "project_managers", path: "/dashboard/employees/project-mangers" },
        /*  { title: "contracts_employees", path: "/dashboard/employees/contracts" },*/
          { title: "engineers", path: "/dashboard/employees/engineers" },
          
        ],
      },
      {

        title: "clients",
        icon: ICONS.clients,
        path: "/dashboard/clients/entrepreneurs",
        index: "4",
       
      },
      {

        title: "projects",
        icon: ICONS.projects,
        path: "/dashboard/projects",
        index: "5",
      },
      {

        title: "CRM",
        icon: ICONS.projects,
        path: "http://crm.damen.darpm.com/admin/crm/clients",
        index: "6",
      },
      {

        title: "appointments",
        icon: ICONS.projects,
        path: "/dashboard/appointments",
        index: "7",
      },
      {

        title: "finances",
        icon: ICONS.wallet,
        path: "/dashboard/finances",
        index: "8",
      },
      {
        title: "settings",
        icon: ICONS.settings,
        path: "/dashboard/settings",
        index: "9",
      },
    

    ],
  },
];

export default navConfig;
