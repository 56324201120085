import sum from "lodash/sum";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import {
  getAllUsers,
  createUser,
  deleteUser,
  updateUser,
  getUserByID,
  getAllEngineers,
  getAllProjectManagers,
  inActiveManagerServices,
  inActiveUserServices,
  activeManagerServices,
  updateProjectManager,
  getAdditionalRules,
  createProjectManager,
  getAllContractors,
  approveContractorService,
  inActiveContractorService,
  denyContractorService,
  deleteContractorService,
  searchContractorService,
  getAllProjectManagersWithoutPag,
  getRolesService,
  getUnExistsEmployeeService,
  getExistsEmployeeService
} from "../services/user_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  usersList: [],
  clienstsList: [],
  managersList: [],
  engineerList: [],
  contractorList: [],
  usersListPagination: {},
  userId: null,
  userInfo: null,
  isLoadingActive: false,
  additionalsRules: [],
  isLoadingCreate: false,
  contractores: [],
  isLoadingInActive: false,
  isLoadingDeny: false,
  managersList: [],
  roles: [],
  forAssignEngineer:[],
  existsEmployee:[]
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setProjectManagerId: (state, action) => {
      state.userId = action.payload;
    },
  },
  extraReducers: {
    // ==> get Users
    [getAllUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.usersList = action.payload.data.data;
      state.usersListPagination = action.payload.data.meta;
    },
    [getAllUsers.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAllUsers.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> get Engineers
    [getAllEngineers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.usersList = action.payload.data.data;
      state.usersListPagination = action.payload.data.meta;
    },
    [getAllEngineers.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAllEngineers.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> get Project Managers
    [getAllProjectManagers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.usersList = action.payload.data.data;
      state.usersListPagination = action.payload.data.meta;
    },
    [getAllProjectManagers.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAllProjectManagers.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> get Project Managers
    [getAllProjectManagersWithoutPag.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.managersList = action.payload.data.data;
    },
    [getAllProjectManagersWithoutPag.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAllProjectManagersWithoutPag.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> get Project Managers
    [getRolesService.fulfilled]: (state, action) => {
      state.isLoading = false;
      console.log("roles from slice")

      console.log(action.payload.data)
      state.roles = action?.payload?.data;
    },
    [getRolesService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getRolesService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> Create User
    [createUser.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createUser.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [createUser.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> Delete User
    [deleteUser.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [deleteUser.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [deleteUser.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> Update User
    [updateUser.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateUser.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [updateUser.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> Get User By id
    [getUserByID.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userInfo = action.payload.data;
    },
    [getUserByID.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getUserByID.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> inActive Manager Services
    [inActiveManagerServices.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [inActiveManagerServices.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [inActiveManagerServices.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> inActive User Services
    [inActiveUserServices.fulfilled]: (state, action) => {
      // state.isLoading = false;
    },
    [inActiveUserServices.rejected]: (state, action) => {
      //  state.isLoading = false;
    },
    [inActiveUserServices.pending]: (state) => {
      //  state.isLoading = true;
    },

    // ==> Active Manager Services
    [activeManagerServices.fulfilled]: (state, action) => {
      state.isLoadingActive = false;
    },
    [activeManagerServices.rejected]: (state, action) => {
      state.isLoadingActive = false;
    },
    [activeManagerServices.pending]: (state) => {
      state.isLoadingActive = true;
    },

    // ==> Active Manager Services
    [getAdditionalRules.fulfilled]: (state, action) => {
      state.additionalsRules = action.payload.data;
    },
    [getAdditionalRules.rejected]: (state, action) => {
      // state.isLoadingActive = false;
    },
    [getAdditionalRules.pending]: (state) => {
      // state.isLoadingActive = true;
    },

    // ==> Create Project Manager
    [createProjectManager.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createProjectManager.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createProjectManager.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    // ==> get Contractor
    [getAllContractors.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.usersList = action.payload.data.data;
      state.usersListPagination = action.payload.data.meta;
    },
    [getAllContractors.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAllContractors.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> get Contractor
    [approveContractorService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [approveContractorService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [approveContractorService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> get Contractor
    [denyContractorService.fulfilled]: (state, action) => {
      state.isLoadingDeny = false;
    },
    [denyContractorService.rejected]: (state, action) => {
      state.isLoadingDeny = false;
    },
    [denyContractorService.pending]: (state) => {
      state.isLoadingDeny = true;
    },

    // ==> get Contractor
    [inActiveContractorService.fulfilled]: (state, action) => {
      state.isLoadingInActive = false;
    },
    [inActiveContractorService.rejected]: (state, action) => {
      state.isLoadingInActive = false;
    },
    [inActiveContractorService.pending]: (state) => {
      state.isLoadingInActive = true;
    },

    // ==> DeLETE Contractor
    [deleteContractorService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteContractorService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteContractorService.pending]: (state) => {
      state.isLoadingDelete = true;
    },

    // ==> SEARCH Contractor
    [searchContractorService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.usersList = action.payload.data.data;
      state.usersListPagination = action.payload.data.meta;
    },
    [searchContractorService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [searchContractorService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> SEARCH Contractor
    [getUnExistsEmployeeService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.forAssignEngineer = action?.payload?.data;
    },
    [getUnExistsEmployeeService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getUnExistsEmployeeService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> EXISTS EMPLOYEE
    [getExistsEmployeeService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.existsEmployee = action?.payload?.data;
    },
    [getExistsEmployeeService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getExistsEmployeeService.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setUserInfo, setProjectManagerId } = slice.actions;
