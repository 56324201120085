// ----------------------------------------------------------------------

const ar = {
  // required
  email_is_required: ` مطلوب إدخال البريد الإلكتروني`,
  password_is_required: `مطلوب إدخال كلمة المرور `,
  //tabels
  id: `المعرف`,
  name: `الاسم`,
  email: `الإيميل`,
  phone_number: `رقم الهاتف`,
  projrcts: `المشاريع`,
  status: `الحالة`,
  action: `الحدث`,
  //
  dashboard: `لوحة التحكم`,
  calendar: `التقويم`,
  employees: `الموظفون`,
  clients: `العملاء`,
  entrepreneurs: `أصحاب المشاريع`,
  potential_clients: `عملاء محتملون`,
  projects: `المشاريع`,
  finances: `الماليات`,
  settings: `الإعدادات`,
  my_account: `حسابي`,
  contact_us: `تواصل معنا`,
  dar_employees: `موظفوا دار`,
  contracts_employees: `موظفوا العقود`,
  logout: `تسجيل الخروج`,
  //
  login: `تسجيل الدخول`,
  user_name: `اسم المستخدم `,
  password: `كلمة المرور`,
  forget_password: `هل نسيت كلمة المرور ؟`,
  login_to: `تسجيل الدخول في`,
  //
  add_new_employee: `  إضافة موظف جديد`,
  add_new_client: `  إضافة عميل جديد`,
  full_name: `الاسم الكامل`,
  gender: `الجنس`,
  male: `ذكر`,
  female: `أنثى`,
  role: `الأدوار الإضافية`,
  submit: `اشتراك`,
  submit_and_add_more: `اشتراك وإضافة آخر`,
  cancel: `إلغاء`,
  send: `إرسال`,
  upload_excel_file: `تحميل ملف اكسل`,
  INACTIVE:`غير نشط`,
  ACTIVE:`نشط`,
  
  total: `الإجمالي`,
  print: `طباعة`,
  export: `تصدير`,
  sort: `ترتيب`,
  users: `المشرفين `,
  prospect_client: `العملاء المحتملون`,
  specialization: `الاختصاص`,
  specialization_is_required: `الاختصاص مطلوب`,
  add_new_user: `إضافة مستخدم`,
  registered_at: `تم التسجيل في`,
  phone_numbers: `أرقام الهواتف`,
  avatar_url: `الصورة`,
  is_active: `فعال`,
  delete: `حذف`,
  edit: `تعديل`,
  rows_per_page: `عدد الأسطر بالصفحة`,
  email_must_be_a_valid_email_address: `يجب أن يكون البريد الإلكتروني  صالحًا`,
  profile_picture: `صورة البروفايل`,
  group: `الصلاحيات`,
  fullname_is_required: `الاسم الكامل مطلوب`,
  role_is_required: `الصلاحيات مطلوبة`,
  gender_name_is_required: `الجنس مطلوب`,
  delete_item: `حذف عنصر`,
  are_you_sure_you_want_to_delete_this_item: `هل أنت متأكد من أنك تريد حذف هذا العنصر ؟`,
  phone_number_is_required: `رقم الهاتف مطلوب`,
  avatar_is_required: `صورة الملف الشخصي مطلوبة`,
  email_must_be_a_valid_email_address: `يجب أن يكون البريد الإلكتروني عنوان بريد إلكتروني صالحًا`,
  add_new_project: `إضافة مشروع جديد`,
  start_date: `وقت البداية`,
  in_progress_paths: `مسارات التقدم`,
  project_manager: `مدير المشروع`,
  project_owner: `مالك المشروع`,
  bulk_id: `Bulk ID`,
  piece_number: ` قطعة رقم`,
  accessibility: `شكل الارض`,
  floor_area: `مساحة الارض `,
  project_owner: `مالك المشروع`,
  street_name: `اسم الشارع`,
  start_immediately: `بدأ المشروع للتو`,
  name_is_required: `الاسم مطلوب`,
  piece_number_is_required: `رقم القطع مطلوب`,
  bulk_id_is_required: `مطلوب`,
  accessibility_is_required: `شكل الارض مطلوب`,
  city_is_required: `المحافظة مطلوبة`,
  province: `المدينة`,
  city: `المحافظة`,
  province_is_required: `المدينة مطلوبة`,
  floor_area_is_required: `المساحة مطلوبة`,
  project_owner_is_required: `مالك المشروع مطلوب`,
  street_name_is_required: `اسم الشارع مطلوب`,
  start_immediately_is_required: `مطلوب`,
  postpone: `إيقاف مؤقت`,
  archive: `أرشفة`,
  resume: `استئناف`,
  confirm: `تأكيد`,
  assign_project_manager: `إضافة مدير للمشروع`,
  project_managers: `مدراء المشاريع`,
  completed_projects: `المشاريع المكتملة`,
  inactive: `غير نشط`,
  active: `نشط`,
  voucher_number: `رقم القسيمة`,
  voucher_number_is_required: `رقم القسيمة مطلوب`,
  assign_emplyee: `إضافة موظف`,
  assign: `إسناد`,
  end_project: `إنهاء المشروع`,
  start_project: `بدء المشروع`,
  end: `إنهاء`,
  current_projects: `المشاريع الحالية`,
  phases: `المراحل`,
  type_of_project: `نوع المشروع`,
  notes: `ملاحظات (اختيارية)`,
  are_you_sure_to_finish_the_project: `هل أنت متأكد من إنهاء المشروع؟`,
  the_owner_will_be_notified_and_his_approval_will_be_requested: `سيتم اعلام المالك وطلب موافقته`,
  type_of_new_project: `نوع مشروع جديد`,
  add_new_type: `اضافة نوع جديد`,
  save: `حفظ`,
  the_assign: `الاسناد`,
  Switch: `تبديل`,
  add_new_assign: `اضافة اسناد جديد`,
  edit_project: `تعديل المشروع`,
  project: `المشروع`,
  add_new_manager: `اضافة مدير جديد`,
  type_of_project_in_arabic: `نوع المشروع باللغة العربية`,
  type_of_project_in_english: `نوع المشروع باللغة الانكليزية`,
  set_as_project_manager: `تعيين كمدير للمشروع`,
  are_you_sure_to_stop_the_project: `هل أنت متأكد من إيقاف المشروع؟`,
  the_owner_will_be_notified_and_his_approval_will_be_sought: `سيتم إعلام المالك وطلب موافقته`,
  are_you_sure_to_start_the_project: `هل أنت متأكد من بدء المشروع؟`,
  the_owner_and_all_employees_associated_with_the_project_will_be_notified: `سيتم إعلام المالك وجميع الموظفين المرتبطين بالمشروع`,
  are_you_sure_to_end_the_project: `هل أنت متأكد من إنهاء المشروع؟`,
  engineers: `المهندسين`,
  work: `الوظيفة`,
  add_new_engineer: `اضافة مهندس جديد`,
  add_new_project_manager: `اضافة مدير مشروع جديد`,
  assign_to_project: `اسناد لمشروع`,
  activate_employee: `تفعيل موظف`,
  disable_employee: `تعطيل موظف`,
  accept:`قبول`,
  reject:`رفض`,
  delete_employee: `حذف الموظف`,
  architects: ` المعماري`,
  structurals: `بنيوي`,
  executive: `تنفيذي`,
  proj: ` مشروع`,
  projc: ` مشاريع`,
  search_on_employee: `ابحث عن موظف`,
  search_on_projects: `ابحث عن مشروع`,
  please_select_specialization: `الرجاء اختيار اختصاص`,
  assign_new_manager: `اسناد مدير جديد`,
  assign_new_engineer: `اسناد مهندس جديد`,
  executive_supervisors: `المشرفين التنفيذيين`,
  assign_new_supervisor: `اسناد مشرف جديد`,
  choose_engineer: `اختر المهندس`,
  choose_pm: `اختر مدير مشروع`,
  choose_supervisor: `اختر  المشرف`,
  project_manager: `مدير المشروع `,
  no_project_manager_selected: `لم يتم تحديد مدير المشروع`,
  client_status: `حالة العميل`,
  all: `الكل`,
  un_active: `غير فعال`,
  deleted: `محذوف`,
  have_projects: `لديه مشاريع`,
  no_projects: `ليس لديه مشاريع بعد`,
  order: `الترتيب`,
  name_descending: `الاسم تنازليا`,
  name_ascending: `الاسم تصاعديا`,
  active_customers_first: `العملاء النشطون أولا`,
  inactive_customers_first: `العملاء الغير نشطون أولا`,
  the_number_of_projects_is_increasing: `عدد المشاريع تصاعدياً`,
  number_of_projects_descending: `عدد المشاريع تنازلياً`,
  additional_roles: `الادوار الاضافية`,
  electrical_engineer: `مهندس كهربائي`,
  sanitary_engineer: `مهندس صحي`,
  active_client_first: `الموظفون النشطون أولا`,
  inactive_client_first: `الموظفون الغير نشطون أولا`,
  customize: `تخصيص`,
  owner_of_project: `مالك المشروع`,
  manager_of_project: `مدير المشروع`,
  project_status: `حالة المشروع`,
  phase: `المرحلة`,
  design: `التصميم`,
  contracts: `العقود`,
  implementation: `التنفيذ`,
  from: `من`,
  to: `الى`,
  date_added: `تاريخ الاضافة`,
  governorate: `المحافظة`,
  date_descending: `تاريخ الاضافة تصاعديا`,
  date_ascending: `تاريخ الاضافة تنازليا`,
  design_first: `التصميم أولا`,
  contracts_first: `العقود أولا`,
  excute_first: `التنفيذ أولا`,
  contractors:`المقاولون`,
  add_new_contractor:`إضافة مقاول`,
  appointments:"المواعيد",
    add_new_appointment:`إضافة موعد  `
};

export default ar;
