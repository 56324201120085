import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import usersReducer from "./slices/users";
import projectsReducer from "./slices/projects";
import citiesReducer from "./slices/cities";
import appointmentsReducer from "./slices/appointments";
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const userPersistConfig = {
  key: "users",
  storage,
  keyPrefix: "redux-",
  whitelist: ["userInfo"],
};

const projectsPersistConfig = {
  key: "projects",
  storage,
  keyPrefix: "redux-",
  whitelist: ["projectId", "projectById"],
};

const citiesPersistConfig = {
  key: "cikties",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};
const rootReducer = combineReducers({
  //  mail: mailReducer,
  users: persistReducer(userPersistConfig, usersReducer),
  projects: persistReducer(projectsPersistConfig, projectsReducer),
  cities: persistReducer(citiesPersistConfig, citiesReducer),
  appointments: appointmentsReducer,
});

export { rootPersistConfig, rootReducer };
