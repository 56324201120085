import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";

//---------------------------------------------------------------

// GET =>  GET ALL PROJECTS
export const getAllProjects = createAsyncThunk(
  "projects/getAllProjects",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.projectsURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  CREATE PROJECT
export const createProjectsService = createAsyncThunk(
  "projects/createProject",
  async (data, thunkAPI) => {
    console.log("rrrrrrrrr");
    try {
      const response = await axios.post(`/dashboard${URLS.projectsURL}`, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST => START
export const startProjectService = createAsyncThunk(
  "projects/startProject",
  async (id, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.projectsURL}/${id}/start`,
        null
      );
      console.log(response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST => End
export const endProjectService = createAsyncThunk(
  "projects/endProject",
  async (id, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.projectsURL}/${id}/complete`,
        null
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST => POSTPONE
export const postponeProjectService = createAsyncThunk(
  "projects/postponeProject",
  async (id, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.projectsURL}/${id}/postpone`,
        null
      );
      console.log(response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST => ARCHIVE
export const archiveProjectService = createAsyncThunk(
  "projects/archiveProject",
  async (id, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.projectsURL}/${id}/archive`,
        null
      );
      console.log(response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST => RESUME
export const resumeProjectService = createAsyncThunk(
  "projects/resumeProject",
  async (id, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.projectsURL}/${id}/resume`,
        null
      );
      console.log(response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST => ASSIGN PROJECT MANAGER
export const assignProjectManagerService = createAsyncThunk(
  "projects/assign_project_manager",
  async (params, thunkAPI) => {
    try {
      const response = await axios.post(
        `dashboard${URLS.projectsURL}/${params.id}/assign-project-managers`,
        params.data
      );
      console.log(response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET TYPE OF PROJECT
export const getTypeOfProject = createAsyncThunk(
  "projects/getTypeOfProject",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.projectTypeURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  POST TYPE OF PROJECT
export const setTypeOfProject = createAsyncThunk(
  "projects/setTypeOfProject",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.projectTypeURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET PROJECT BY ID
export const getProjectById = createAsyncThunk(
  "projects/getProjectById",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.projectsByIdURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  UPDATE PROJECT
export const updateProject = createAsyncThunk(
  "projects/updateProject",
  async (payload, thunkAPI) => {
    console.log("payload");
    console.log(payload);
    try {
      const response = await axios.post(
        `${URLS.projectsURL}/${payload.id}`,
        payload.data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET => Assignable Users
export const assignableUsersService = createAsyncThunk(
  "projects/assignableUsersService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.projectsURL}/${params.id}/assignable-users`,
        {
          params: {
            ...params,
          },
        }
      );
      console.log(response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  CREATE ASSIGN PROJECT
export const createAssignProjectService = createAsyncThunk(
  "projects/createAssignProjectService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.post(
        `dashboard${URLS.projectsURL}/${params.id}/assign-employees`,
        params.data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET ACCESSIBILTY
export const getAllAccessibilityService = createAsyncThunk(
  "projects/allAccessibility",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.allAccessibilityURL);
      console.log(response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST => Un ASSIGN PROJECT MANAGER
export const unAssignProjectManagerService = createAsyncThunk(
  "projects/unassign_project_manager",
  async (params, thunkAPI) => {
    try {
      const response = await axios.post(
        `/dashboard${URLS.projectsURL}/${params.id}/un-assign_project_manager`,
        params.data
      );
      console.log(response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST => Un ASSIGN EMPLOYEE
export const unAssignEmployeeService = createAsyncThunk(
  "projects/unassign_employee",
  async (params, thunkAPI) => {
    try {
      const response = await axios.post(
        `dashboard${URLS.projectsURL}/${params.project_id}/un-assign-employee`,
        params?.data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET PROJECT To Project managers
export const getProjectToPm = createAsyncThunk(
  "projects/getProjectToPm",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.projectsToPmURL}/${data.id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET PROJECT To Project engineers
export const getProjectToEng = createAsyncThunk(
  "projects/getProjectToEng",
  async (data, thunkAPI) => {
    // const {user} = useSelector((state)=>state.users)
    try {
      const response = await axios.get(`${URLS.projectsToEnURL}/${data.id}`, {
        params: {
          ...data,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
