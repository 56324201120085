import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";

//---------------------------------------------------------------

// GET =>  GET ALL USERS
export const getAllUsers = createAsyncThunk(
  "users/getAllUsers",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.usersURL, {
        params: {
          ...params,
          orderBy: "asc",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET ALL Engineers
export const getAllEngineers = createAsyncThunk(
  "users/getAllEngineers",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.engineersListURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET ALL Managers
export const getAllProjectManagers = createAsyncThunk(
  "users/getAllProjectManagers",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.managersURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET ALL Managers
export const getAllProjectManagersWithoutPag = createAsyncThunk(
  "users/getAllProjectManagersWithoutPag",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.managersURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  CREATE USER
export const createUser = createAsyncThunk(
  "users/createUser",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.userssURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  CREATE USER
export const createProjectManager = createAsyncThunk(
  "users/createProjectManager",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.managersURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// PUT =>  UPDATE Project Manager
export const updateProjectManager = createAsyncThunk(
  "users/updateProjectManager",
  async (payload, thunkAPI) => {
    console.log(payload);

    try {
      const response = await axios.post(
        `${URLS.managersURL}/${payload.id}`,
        payload.data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// DELTE =>  DELETE USER
export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.userssURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// PUT =>  UPDATE USER
export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (payload, thunkAPI) => {
    console.log(payload);

    try {
      const response = await axios.post(
        `${URLS.usersURL}/${payload.id}`,
        payload.data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET USER BY ID
export const getUserByID = createAsyncThunk(
  "users/getUSerByID",
  async (id, data, thunkAPI) => {
    try {
      const response = await axios.put(`${URLS.usersURL}/${id}`, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  INACTIVE PROJECT MANAGER
export const inActiveManagerServices = createAsyncThunk(
  "users/inActiveManager",
  async (id, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.managersURL}/${id}/inactivate`,
        null
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  INACTIVE USER
export const inActiveUserServices = createAsyncThunk(
  "users/inActiveUser",
  async (id, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.managersURL}/${id}/inactivaten`,
        null
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  ACTIVE PROJECT MANAGER
export const activeManagerServices = createAsyncThunk(
  "users/activeManager",
  async (id, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.managersURL}/${id}/activate`,
        null
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//additionalRulesURL

// GET =>  GET ALL Engineers
export const getAdditionalRules = createAsyncThunk(
  "users/getAdditionalRules",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getAdditionalRules, {
        params: {
          ...params,
        },
      });

      console.log(response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET ALL Contractor
export const getAllContractors = createAsyncThunk(
  "users/getAllContractor",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.contractorsURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  CREATE USER
export const createContractor = createAsyncThunk(
  "users/createUser",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.contractorsURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  EDIT CONTRACTOR
export const EditContractorService = createAsyncThunk(
  "users/editContractor",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.contractorsURL}/${data?.id}`,
        data?.data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  APPROVE USER
export const approveContractorService = createAsyncThunk(
  "users/createUser",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.contractorsURL}/${data.id}/approved`,
        { status: data.status }
      );
      console.log(response.data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  DENY USER
export const denyContractorService = createAsyncThunk(
  "users/denyContractor",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.contractorsURL}/${data.id}/deny`,
        { status: data.status }
      );
      console.log(response.data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  INACTIVE USER
export const inActiveContractorService = createAsyncThunk(
  "users/inActiveContractor",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(URLS.inActiveContractorsURL, data);
      console.log(response.data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  DELETE CONTRACTOR
export const deleteContractorService = createAsyncThunk(
  "users/deleteContractor",
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete(URLS.deleteContractorsURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  SEARCH CONTRACTOR
export const searchContractorService = createAsyncThunk(
  "users/searchContractor",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(URLS.contractorSearchURL, {
        params: {
          page: 1,
          items_for_page: 30,
          ...data,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET ROLE SERVICES
export const getRolesService = createAsyncThunk(
  "users/getRoles",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.rolesURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET UNEXISTS EMPLOYEE SERVICES
export const getUnExistsEmployeeService = createAsyncThunk(
  "users/getUnExistsEmployee",
  async (params, thunkAPI) => {
    console.log("params")
    console.log(params)
    try {
      const response = await axios.get(
        `${URLS.unExistsEmployeeURL}/${params?.projectId}/get-un-exists-employees`,
        {
          params: {
            role_id: params?.role_id,
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


// GET =>  GET EXISTS EMPLOYEE SERVICES
export const getExistsEmployeeService = createAsyncThunk(
  "users/getExistsEmployee",
  async (params, thunkAPI) => {
    console.log("params")
    console.log(params)
    try {
      const response = await axios.get(
        `${URLS.unExistsEmployeeURL}/${params?.projectId}/employees`,
        {
          params: {
            role_id: params?.role_id,
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
