import PropTypes from "prop-types";
import { createContext, useEffect, useReducer, useCallback } from "react";
// utils
import axios from "../utils/axios";
//
import { isValidToken, setSession } from "./utils";

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  isRegister: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isRegister: false,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGIN") {
    return {
      ...state,
      isAuthenticated: true,
      isRegister: true,
      email: action.payload.email,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      phoneNumber: action.payload.phoneNumber,
      profilePictureUrl: action.payload.profilePictureUrl,
      id: action.payload.id,
    };
  }
  if (action.type === "REGISTER") {
    return {
      ...state,
      isAuthenticated: false,
      isRegister: true,
      user: null,
    };
  }
  if (action.type === "LOGOUT") {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const Token =
        typeof window !== "undefined" ? localStorage.getItem("Token") : "";

      if (Token && isValidToken(Token)) {
        setSession(Token);
      } else {
        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "INITIAL",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async (data) => {
    const response = await axios.post(
      "/users/login",
      data
    );
    console.log(response);
    console.log(response?.message);
    console.log(response?.status);
    console.log(response?.data?.token);
    setSession(response?.data?.token);

    if (response.status === 200 && response.message === "Login successful") {
      
      window.location.href = "/dashboard";
      localStorage.setItem(
        "user",
        JSON.stringify({
          email: response.data.user.email,
          full_name: response.data.user.full_name,
          group: response.data.user.group,
          gender: response.data.user.gender,
          registered_at: response.data.user.registered_at,
          phone_number: response.data.user.phone_number,
          avatar_url: response.data.user.avatar_url,
          id: response.data.user.data.id,
        })
      );
      console.log(response);
  
    }

    dispatch({
      type: "LOGIN",
      payload: {
        email: response.data.user.email,
        full_name: response.data.user.full_name,
        group: response.data.user.group,
        gender: response.data.data.user.gender,
        registered_at: response.data.data.user.registered_at,
        phone_number: response.data.data.user.phone_number,
        avatar_url: response.data.data.user.avatar_url,
        id: response.data.data.user.id,
      },
    });
  };

  // LOGOUT
  const logout = async () => {
    const response = await axios.post("/users/logout");
    if (
      response.status === 200 &&
      response.message === "Successfully logged out."
    ) {
      localStorage.clear();
      setSession(null);
      dispatch({
        type: "LOGOUT",
      });
      window.location.href = "/";
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
