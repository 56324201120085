export const usersURL = "/clients";

export const userssURL = "/users";

export const engineersListURL ="engineersListForTable"

export const engineersURL = "/engineers";

export const managersURL = "/managers";

export const projectsURL = "/projects";

export const citiesURL = "/cities";

export const provincesURL = "/provinces";

export const projectTypeURL = "/project-types";


export const allAccessibilityURL = "allAccessibility";

export const getAdditionalRules = "additionalRules";

export const projectsByIdURL = "/dashboard/projects";

export const projectsToPmURL = "/dashboard/get-all-projects-to-pm";

export const projectsToEnURL = "/dashboard/get-all-projects-to-engineer";

export const contractorsURL = "/dashboard/contractors";

export const inActiveContractorsURL = "/dashboard/contractors-inactive";

export const deleteContractorsURL="/dashboard/delete-multi-contractors"

export const contractorSearchURL="/dashboard/contractors-search"

export const clientsWithoutPaginationURL="/dashboard/get-all-clients-without-pagination"

export const enginerrsWithoutPaginationURL="/dashboard/get-all-engineers-without-pagination"

export const managersWithoutPaginationURL="/dashboard/get-all-managers-without-pagination"

export const rolesURL="/roles"

export const unExistsEmployeeURL="/dashboard/projects"

export const appointmentsURL="/dashboard/appointments"
