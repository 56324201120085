import { Navigate, useRoutes } from "react-router-dom";
//layout
import DashboardLayout from "../layouts/dashboard/DashboardLayout";
//
import {
  LoginPage,
  ProjectsPage,
  OverviewPage,
  ProjectManagersPage,
  ContractsEmployeesPage,
  ClientsPage,
  CalendarPage,
  FinancePage,
  SettingsPage,
  AddDarEmployeePage,
  EntrepreneurClientsPage,
  PotentialClientsPage,
  UsersPage,
  AddNewUserPage,
  EditUserPage,
  CreateProject,
  ProjectManagerPage,
  EditProject,
  EngineersPage,
  CreateProjectManagerPage,
  EditProjectManagerPage,
  CreateEngineerPage,
  ContractorPage,
  CreateContractorPage,
  EditContractorPage,
  AppointmentsPage,
} from "./elements";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: "/",
      children: [
        {
          element: <Navigate to="auth/login" replace />,
          index: true,
        },
        { path: "auth/login", element: <LoginPage /> },
      ],
    },

    // =============  @@@@ ADMIN @@@@ =========
    // Dashboard
    {
      path: "dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/overview" replace />, index: true },
        { path: "overview", element: <OverviewPage /> },
        {
          path: "users",
          children: [
            { path: "", element: <UsersPage /> },
            { path: "add-new-user", element: <AddNewUserPage /> },
            { path: "edit-user", element: <EditUserPage /> },
          ],
        },
        {
          path: "contractor",
          children: [
            { path: "", element: <ContractorPage /> },
            { path: "add-new-contractor", element: <CreateContractorPage /> },
            { path: "edit-contractor", element: <EditContractorPage /> },
          ],
        },
        {
          path: "employees",
          children: [
            { path: "", element: <ProjectManagersPage /> },
            {
              path: "project-mangers",
              children: [
                { path: "", element: <ProjectManagersPage /> },
                {
                  path: "create-new-projct-manager",
                  element: <CreateProjectManagerPage />,
                },
                { path: "edit-user", element: <EditProjectManagerPage /> },
              ],
            },
            {
              path: "contracts",
              children: [
                { path: "", element: <ContractsEmployeesPage /> },
                { path: "add-new-user", element: <AddNewUserPage /> },
                { path: "edit-user", element: <EditUserPage /> },
              ],
            },
            {
              path: "engineers",
              children: [
                { path: "", element: <EngineersPage /> },
                { path: "add-new-engineer", element: <CreateEngineerPage /> },
                { path: "edit-user", element: <EditUserPage /> },
              ],
            },
          ],
        },
        {
          path: "projects",
          children: [
            { path: "", element: <ProjectsPage /> },
            { path: "add-new-project", element: <CreateProject /> },
            { path: "edit-project", element: <EditProject /> },

            { path: "assign-project-manager", element: <ProjectManagerPage /> },
            { path: "assign-employee", element: <ProjectManagerPage /> },
            //assign-project-manager
          ],
        },
        {
          path: "clients",
          children: [
            { path: "", element: <EntrepreneurClientsPage /> },
            {
              path: "entrepreneurs",
              children: [
                { path: "", element: <EntrepreneurClientsPage /> },
                { path: "add-new-user", element: <AddNewUserPage /> },
                { path: "edit-user", element: <EditUserPage /> },
              ],
            },
            {
              path: "potential",
              children: [
                { path: "", element: <PotentialClientsPage /> },
                { path: "add-new-user", element: <AddNewUserPage /> },
                { path: "edit-user", element: <EditUserPage /> },
              ],
            },
          ],
        },
        { path: "calendar", element: <CalendarPage /> },
        { path: "appointments", element: <AppointmentsPage /> },
        { path: "finances", element: <FinancePage /> },
        { path: "settings", element: <SettingsPage /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
