// ----------------------------------------------------------------------

const en = {
  // required
  email_is_required: `Email is required`,
  password_is_required: `Password is required`,
  //tabels
  id: `id`,
  name: `name`,
  email: `email`,
  phone_number: ` phone number`,
  projrcts: `projects`,
  status: `status`,
  action: `action`,
  //
  dashboard: `Dashboard`,
  calendar: `Calendar`,
  employees: `Employees`,
  clients: `Clients`,
  entrepreneurs: `Entrepreneurs`,
  potential_clients: `Potential clients`,
  projects: `Projects`,
  finances: `Finances`,
  settings: `Settings`,
  my_account: `My Account`,
  contact_us: `Contact us`,
  dar_employees: `Dar employees`,
  contracts_employees: `Contracts employees`,
  logout: `Log Out`,
  //
  login: `Login`,
  user_name: `User Name`,
  password: `Password`,
  forget_password: `Forget Password ?`,
  login_to: `Login to`,
  //
  add_new_employee: `Add New Employee`,
  add_new_client: `Add New Client`,
  full_name: `Full Name`,
  gender: `Gender`,
  male: `Male`,
  female: `Female`,
  role: `Addition Roles`,
  submit: `Submit`,
  submit_and_add_more: `Submit and add more`,
  cancel: `Cancel`,
  send: `Send`,
  upload_excel_file: `Upload Excel File`,
  INACTIVE:`Inactive`,
  ACTIVE:`Active`,
  total: `Total`,
  print: `Print`,
  export: `Export`,
  sort: `Sort`,
  users: `Super Admins`,
  prospect_client: `Prospect Client`,
  specialization: `specialization`,
  specialization_is_required: `Specialization is required`,
  add_new_user: `Add New User`,
  registered_at: `Registered At`,
  phone_numbers: `Phone Numbers`,
  avatar_url: `Avatar`,
  is_active: `Is Active`,
  delete: `delete`,
  edit: `Edit`,
  rows_per_page: `Rows Per Page`,
  email_must_be_a_valid_email_address: `Email must be a valid email address`,
  profile_picture: `Profile Picture`,
  group: `group`,
  fullname_is_required: `Full Name is required`,
  role_is_required: `Role is required`,
  gender_name_is_required: `Gender is required`,
  delete_item: `Delete Item `,
  are_you_sure_you_want_to_delete_this_item: `Are you sure you want to delete this item?`,
  phone_number_is_required: `Phone Number is required`,
  avatar_is_required: `Avatar is required`,
  email_must_be_a_valid_email_address: `email must be a valid email address`,
  add_new_project: `Add New Project`,
  start_date: `Start Date`,
  in_progress_paths: `In Progress Paths`,
  project_manager: `Project Manager`,
  project_owner: `Project Owner`,
  bulk_id: `Bulk ID`,
  piece_number: `Land Number`,
  accessibility: `Land Shape`,
  floor_area: `Land Area`,
  project_owner: `Project Owner`,
  street_name: `Street Name`,
  start_immediately: `Start Immediately`,
  name_is_required: `Name is required`,
  piece_number_is_required: `Land Number is required`,
  bulk_id_is_required: `Bulk ID is required`,
  accessibility_is_required: `Land Shape is required`,
  city_is_required: `City is required`,
  province: `Province`,
  city: `City`,
  province_is_required: `Province is required`,
  floor_area_is_required: `Land Area is required`,
  project_owner_is_required: `Project Owner is required`,
  street_name_is_required: `Street Name is required`,
  start_immediately_is_required: `Start immediately is required`,
  postpone: `POSTPONE`,
  archive: `ARCHIIVE`,
  resume: `RESUME`,
  confirm: `Confirm`,
  assign_project_manager: `Assign project manager`,
  project_managers: `Project Managers`,
  completed_projects: `Completed Projects`,
  inactive: `Inactive`,
  active: `Active`,
  voucher_number: `Block number`,
  voucher_number_is_required: `Block number is required`,
  assign_emplyee: `Assign Employee`,
  assign: `Assign`,
  end_project: `End Project`,
  start_project: `Start Project`,
  end: `End`,
  current_projects: `Current Projects`,
  phases: `Phases`,
  type_of_project: `Type of project`,
  notes: `Notes(Optional)`,
  are_you_sure_to_finish_the_project: `Are you sure to finish the project ?`,
  the_owner_will_be_notified_and_his_approval_will_be_requested: ` The owner will be notified and his approval will be requested`,
  type_of_new_project: `Type of new project`,
  add_new_type: `Add new type`,
  save: `Save`,
  the_assign: `The assign`,
  Switch: `Switch`,
  add_new_assign: `Add new assign`,
  edit_project: `Edit project`,
  project: `Project`,
  add_new_manager: `Add new manager`,
  type_of_project_in_arabic: `Type of project in arabic`,
  type_of_project_in_english: `Type of project in english`,
  set_as_project_manager: `Set as project manager`,
  are_you_sure_to_stop_the_project: `Are you sure to stop the project?`,
  the_owner_will_be_notified_and_his_approval_will_be_sought: `The owner will be notified and his approval will be sought`,
  are_you_sure_to_start_the_project: `Are you sure to start the project?`,
  the_owner_and_all_employees_associated_with_the_project_will_be_notified: `The owner and all employees associated with the project will be notified`,
  are_you_sure_to_end_the_project: `Are you sure to end the project?`,
  work: `Work`,
  engineers: `Engineers`,
  add_new_engineer: `Add New Engineer`,
  add_new_project_manager: `Add New Project Manager`,
  assign_to_project: `Assign to project`,
  activate_employee: `Activate employee`,
  disable_employee: `Disable employee`,
  accept:`Accept`,
  reject:`Reject`,
  delete_employee: `Delete employee`,
  architects: `Architects`,
  structurals: `Structurals`,
  executive: `Executive`,
  proj: `project`,
  projc: ` projects`,
  specialization: `Specialization`,
  search_on_employee: `Search on employee`,
  search_on_projects: `Search on project`,
  please_select_specialization: `Please select specialization`,
  assign_new_manager: `Assign new manager`,
  assign_new_engineer: `Assign new engineer`,
  executive_supervisors: `Executive supervisors`,
  assign_new_supervisor: `Assign new supervisor`,
  choose_engineer: `Choose Engineer`,
  choose_pm: `Choose Project Manager`,
  choose_supervisor: `Choose Supervisor`,
  project_manager: `Project Manager`,
  no_project_manager_selected: `No project manager selected`,
  client_status: `Client status`,
  all: `All`,
  un_active: `Inactive`,
  deleted: `deleted`,
  have_projects: `Have projects`,
  no_projects: `No projects yet`,
  order: `Order`,
  name_descending: `Name descending`,
  name_ascending: `Name ascending`,
  active_customers_first: `Active customers first`,
  inactive_customers_first: `Inactive customers first`,
  the_number_of_projects_is_increasing: `number of projects is increasing`,
  number_of_projects_descending: `number of projects descending`,
  additional_roles: `Additional roles`,
  electrical_engineer: `Electrical engineer`,
  sanitary_engineer: `Sanitary engineer`,
  active_client_first: `Active client first`,
  inactive_client_first: `Inactive client first`,
  customize: `Customize`,
  owner_of_project: `Owner of project`,
  manager_of_project: `Manager of project`,
  project_status: `Project status`,
  phase: `Phase`,
  design: `design`,
  contracts: `contracts`,
  implementation: `implementation`,
  from: `From`,
  to: `To`,
  date_added: `Date added`,
  governorate: `Governorate`,
  date_descending: `Date added descending`,
  date_ascending: `Date added ascending`,
  design_first: `Design first`,
  contracts_first: `Contracts first`,
  excute_first: `Excute first`,
  contractors:`Contractors`,
  add_new_contractor:`Add new contractor`,
  appointments:"Appointments",
  add_new_appointment:`Add Appointment`
};

export default en;
