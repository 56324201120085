import { createSlice } from "@reduxjs/toolkit";
import {
  getAllProjects,
  createProjectsService,
} from "../services/project_service";
import {
  createCityService,
  getAllCitiesService,
  getAllProvincesService,
} from "../services/cities_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  citiesList: [],
  citiesListPagination: 0,
  cityId: null,
  cityInfo: null,
  provincesList: [],
  provincesListPagination: 0,
  provinceId: null,
  provinceInfo: null,
};

const slice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    setCityInfo: (state, action) => {
      state.cityInfo = action.payload;
    },
  },
  extraReducers: {
    // ==> GET Cities
    [getAllCitiesService.fulfilled]: (state, action) => {
      state.isLoading = false;
      console.log(action.payload.data);
      state.citiesList = action.payload.data;
      state.citiesListPagination = action.payload?.meta;
    },
    [getAllCitiesService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAllCitiesService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> Create CITY
    [createCityService.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createProjectsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [createProjectsService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> GET Provinces
    [getAllProvincesService.fulfilled]: (state, action) => {
      state.provincesList = action.payload.data;
      state.provincesListPagination = action.payload?.meta;
    },
    [getAllProvincesService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAllProvincesService.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setCityInfo } = slice.actions;
