import { createSlice } from "@reduxjs/toolkit";
import { getAllAppointmentsService } from "../services/appointments_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  appointmentsList: [],
};

const slice = createSlice({
  name: "appointments",
  initialState,
  reducers: {},
  extraReducers: {
    // ==> GET ALL APPOINTMENTS
    [getAllAppointmentsService.fulfilled]: (state, action) => {
      state.isLoading = false;

      state.appointmentsList = action.payload.data.data;
    },
    [getAllAppointmentsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAllAppointmentsService.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;
